import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import Banner from "../components/Content/Banner/Index";
import LeadIn from "../components/Content/LeadIn/Index";
import FeatureProduct from "../components/Content/FeatureProduct/Index";
import FeatureCaseStudy from "../components/Content/FeatureArticle/Dynamic";

// Assets
import brandConex from "../assets/logos/logo.svg";
import iconSearch from "../assets/icons/icon-search.svg";

// Data
import bannerHeroItems from "../pages/static-pages/content/home.yaml";
import { UseBlogData } from "../helpers/hooks/BlogQuery";

// markup
const IndexPage: React.FC = () => {
  const featured = UseBlogData();

  return (
    <Layout>
      <Seo
        title="Home"
        description="Backed by over 110 years of innovation, Conex Bänninger is the recognised leader in press fittings across the plumbing and HVAC-R industries."
      />
      <Banner type="hero" removePadding={true} data={bannerHeroItems.items} />
      <LeadIn
        brandMediaUrl={brandConex}
        brandMediaTitle="Conex Bänninger Logo"
        brandMediaWidth="306px"
        summary="Backed by over 110 years of innovation, Conex Bänninger is the recognised leader in press fittings across the plumbing and HVAC-R industries."
        paddingSize="small"
      />
      <FeatureProduct />
      <LeadIn
        section="primary"
        brandMediaUrl={iconSearch}
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Need help finding the right system for the job?"
        paddingSize="small"
        buttonValue="Launch Product Selector"
        buttonUrl="#js-product-selector-app"
        buttonModal={true}
      />
      <FeatureCaseStudy paddingSize="large" data={featured.home} />
    </Layout>
  );
};

export default IndexPage;
