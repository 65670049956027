import React from "react";

// components
import Icons from "../Icons/Index";
import FeatureProductItem from "./Item";
import { BrandAttributes } from "../../../helpers/hooks/BrandQuery";

interface Props {
  removePadding?: boolean | null;
}

const defaultProps: Props = {
  removePadding: false,
};

const FeatureProduct: React.FC<Props> = (props) => {
  const { removePadding } = props;

  return (
    <section
      className={`c-feature-product uk-section c-noprint ${
        removePadding && "uk-padding-remove"
      }`}
    >
      <div className="uk-container">
        <div className="uk-width-1-1 uk-margin-auto">
          <div
            className="c-feature-product__controls uk-text-center"
            data-uk-switcher="swiping: false; animation: uk-animation-fade; toggle: > *"
            role="tablist"
          >
            <button
              className="uk-inline uk-button uk-text-small uk-text-uppercase uk-margin-small-right"
              type="button"
              aria-controls="data-fittings"
              role="tab"
            >
              Fittings
              <span className="uk-display-inline-block uk-position-bottom-center"></span>
            </button>
            <button
              className="uk-inline uk-button uk-text-small uk-text-uppercase"
              type="button"
              aria-controls="data-tools"
            >
              Tools
              <span className="uk-display-inline-block uk-position-bottom-center"></span>
            </button>
          </div>
          <div className="uk-switcher uk-margin-large uk-margin-large-bottom">
            <div>
              <div
                className="uk-position-relative uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top"
                tabIndex={-1}
                data-uk-slider="finite: true"
                id="data-fittings"
                role="tabpanel"
              >
                <ul className="uk-slider-items uk-grid uk-grid-medium">
                  <FeatureProductItem
                    brandMediaUrl={BrandAttributes["bpress"]?.icon}
                    brandMediaTitle={BrandAttributes["bpress"]?.title}
                    brandMediaWidth={BrandAttributes["bpress"]?.width}
                    productImageUrl={BrandAttributes["bpress"]?.src}
                    productImageTitle={BrandAttributes["bpress"]?.title}
                    pageUrl={BrandAttributes["bpress"]?.url}
                  >
                    <div className="uk-flex">
                      <Icons iconType="water" />
                      <Icons iconType="gas" />
                      <Icons iconType="temp" />
                    </div>
                  </FeatureProductItem>
                  <FeatureProductItem
                    brandMediaUrl={BrandAttributes["bpressxl"]?.icon}
                    brandMediaTitle={BrandAttributes["bpressxl"]?.title}
                    brandMediaWidth={BrandAttributes["bpressxl"]?.width}
                    productImageUrl={BrandAttributes["bpressxl"]?.src}
                    productImageTitle={BrandAttributes["bpressxl"]?.title}
                    pageUrl={BrandAttributes["bpressxl"]?.url}
                  >
                    <div className="uk-flex">
                      <Icons iconType="water" />
                      <Icons iconType="gas" />
                    </div>
                  </FeatureProductItem>
                  <FeatureProductItem
                    brandMediaUrl={BrandAttributes["bmaxipro"]?.icon}
                    brandMediaTitle={BrandAttributes["bmaxipro"]?.title}
                    brandMediaWidth={BrandAttributes["bmaxipro"]?.width}
                    productImageUrl={BrandAttributes["bmaxipro"]?.src}
                    productImageTitle={BrandAttributes["bmaxipro"]?.title}
                    pageUrl={BrandAttributes["bmaxipro"]?.url}
                  >
                    <div className="uk-flex">
                      <Icons iconType="cold" />
                    </div>
                  </FeatureProductItem>
                  <FeatureProductItem
                    brandMediaUrl={
                      BrandAttributes["bpressstainlesssteelv2"]?.icon
                    }
                    brandMediaTitle={
                      BrandAttributes["bpressstainlesssteelv2"]?.title
                    }
                    brandMediaWidth={
                      BrandAttributes["bpressstainlesssteelv2"]?.width
                    }
                    productImageUrl={
                      BrandAttributes["bpressstainlesssteelv2"]?.src
                    }
                    productImageTitle={
                      BrandAttributes["bpressstainlesssteelv2"]?.title
                    }
                    pageUrl={BrandAttributes["bpressstainlesssteelv2"]?.url}
                  >
                    <div className="uk-flex">
                      <Icons iconType="water" />
                    </div>
                  </FeatureProductItem>
                </ul>
              </div>
            </div>
            <div>
              <div
                className="uk-position-relative"
                tabIndex={-1}
                data-uk-slider="true"
                id="data-tools"
                role="tabpanel"
              >
                <ul className="uk-slider-items uk-grid uk-grid-small">
                  <FeatureProductItem
                    brandMediaUrl={BrandAttributes["romaxcompacttt"]?.icon}
                    brandMediaTitle={BrandAttributes["romaxcompacttt"]?.title}
                    brandMediaWidth={BrandAttributes["romaxcompacttt"]?.width}
                    productImageUrl={BrandAttributes["romaxcompacttt"]?.src}
                    productImageTitle={BrandAttributes["romaxcompacttt"]?.title}
                    pageUrl={BrandAttributes["romaxcompacttt"]?.url}
                  />
                  <FeatureProductItem
                    brandMediaUrl={BrandAttributes["romax400"]?.icon}
                    brandMediaTitle={BrandAttributes["romax400"]?.title}
                    brandMediaWidth={BrandAttributes["romax400"]?.width}
                    productImageUrl={BrandAttributes["romax400"]?.src}
                    productImageTitle={BrandAttributes["romax400"]?.title}
                    pageUrl={BrandAttributes["romax400"]?.url}
                  />
                  <FeatureProductItem
                    brandMediaUrl={BrandAttributes["accessories"]?.icon}
                    brandMediaTitle={BrandAttributes["accessories"]?.title}
                    brandMediaWidth={BrandAttributes["accessories"]?.width}
                    productImageUrl={BrandAttributes["accessories"]?.src}
                    productImageTitle={BrandAttributes["accessories"]?.title}
                    pageUrl={BrandAttributes["accessories"]?.url}
                  />
                  <FeatureProductItem
                    brandMediaUrl={BrandAttributes["approved"]?.icon}
                    brandMediaTitle={BrandAttributes["approved"]?.title}
                    brandMediaWidth={BrandAttributes["approved"]?.width}
                    productImageUrl={BrandAttributes["approved"]?.src}
                    productImageTitle={BrandAttributes["approved"]?.title}
                    pageUrl={BrandAttributes["approved"]?.url}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureProduct;

FeatureProduct.defaultProps = defaultProps;
