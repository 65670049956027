import { graphql, useStaticQuery } from "gatsby";

export const UseBlogData = (): any => {
  graphql`
    fragment BlogFields on MarkdownRemark {
      frontmatter {
        date
        category
        images {
          altText
          path
        }
        slug
        summary
        taxonomy {
          key
          value
        }
        title
      }
    }
  `;
  /*
   * Possible options set:
   * - featuredHome
   * - featuredOnBpress
   * - featuredOnBpressXl
   * - featuredOnBMaxiPro
   * - featuredOnBpressStainlessSteel
   *
   * This is used across the components
   * It pulls in the case study feature
   * import { UseBlogData } from "foo";
   * const featured = UseBlogData();
   *
   */

  const featured = useStaticQuery(
    graphql`
      query {
        home: allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          limit: 1
          filter: { frontmatter: { featuredHome: { eq: true } } }
        ) {
          nodes {
            ...BlogFields
          }
        }
        bpress: allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          limit: 1
          filter: { frontmatter: { featuredOnBpress: { eq: true } } }
        ) {
          nodes {
            ...BlogFields
          }
        }
        bpressxl: allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          limit: 1
          filter: { frontmatter: { featuredOnBpressXl: { eq: true } } }
        ) {
          nodes {
            ...BlogFields
          }
        }
        bmaxipro: allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          limit: 1
          filter: { frontmatter: { featuredOnBMaxiPro: { eq: true } } }
        ) {
          nodes {
            ...BlogFields
          }
        }
        bpressstainlesssteel: allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          limit: 1
          filter: {
            frontmatter: { featuredOnBpressStainlessSteel: { eq: true } }
          }
        ) {
          nodes {
            ...BlogFields
          }
        }
      }
    `
  );
  return featured;
};
