import React from "react";
import { Link } from "gatsby";

// data
import { BrandAttributes } from "../../../helpers/hooks/BrandQuery";

interface TaxonomyData {
  key: string;
  value: string;
}

interface ImageData {
  altText: string;
  caption: string;
  path: string;
}

interface Props {
  readonly data: PageNodes;
  paddingSize?: "xsmall" | "small" | "medium" | "large" | "xlarge";
}

interface PageNodes {
  nodes: PageQueryField[] | [];
}

interface PageQueryField {
  frontmatter: {
    date: string;
    category: string;
    images: ImageData[] | [];
    slug: string;
    summary: string;
    taxonomy: TaxonomyData[] | [];
    title: string;
  };
}

const FeatureCaseStudy: React.FC<Props> = ({ data, paddingSize }) => {
  const featuredData = data?.nodes[0]?.frontmatter;
  const { title, category, images, slug, summary, taxonomy } =
    featuredData || [];
  const featuredImage = images?.length ? images[0] : undefined;
  const brandType = category ? category.replace(/\W+/g, "").toLowerCase() : "";
  const brandExists =
    typeof BrandAttributes[brandType] != "undefined" ? true : false;

  return (
    <>
      {featuredData && (
        <section
          className={`c-feature-article uk-section uk-section-${paddingSize} c-noprint`}
        >
          <div className="uk-container">
            <h2 className="c-feature-article__heading uk-h5 uk-h4@m uk-h3@l uk-h2@xl">
              Featured Case Study
            </h2>
            <hr className="uk-divider-small" />
            <div
              className="c-feature-article__wrapper uk-flex uk-grid-collapse uk-grid-match uk-background-muted"
              data-uk-grid=""
            >
              <div className="uk-flex-middle uk-width-1-2@m uk-flex-1">
                <div className="c-feature-article__container uk-section uk-section-small">
                  <div
                    className="c-feature-article__content"
                    data-uk-scrollspy="target: > *; cls: uk-animation-slide-bottom-small; delay: 100"
                  >
                    {brandExists && (
                      <div
                        className="c-feature-article__brand"
                        style={{ width: BrandAttributes[brandType].width }}
                      >
                        <img
                          width=""
                          height=""
                          alt={BrandAttributes[brandType]?.title}
                          src={BrandAttributes[brandType]?.icon}
                          className="uk-width-1-1"
                        />
                      </div>
                    )}
                    {summary && (
                      <div
                        className="uk-h5 uk-h4@xl uk-margin-top"
                        dangerouslySetInnerHTML={{ __html: summary }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {featuredImage && (
                <div className="c-feature-article__image uk-width-1-2@m uk-flex-middle uk-flex-first uk-flex-first@s">
                  <Link
                    to={slug}
                    title={title}
                    className="uk-animation-toggle"
                    tabIndex={0}
                  >
                    <div className="uk-overflow-hidden">
                      <div className="uk-cover-container uk-animation-kenburns">
                        <canvas width="630" height="520"></canvas>
                        <img
                          src={featuredImage?.path}
                          alt={featuredImage?.altText}
                          data-uk-cover=""
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              )}
            </div>
            <div
              className="uk-flex uk-flex-middle uk-child-width-1-1 uk-child-width-auto@s uk-grid-medium uk-margin-medium"
              data-uk-grid=""
            >
              <div className="uk-width-expand@m">
                <dl
                  className="uk-description-list uk-child-width-1-1 uk-child-width-1-4@s uk-width-expand@l uk-grid-small"
                  data-uk-grid=""
                >
                  {taxonomy &&
                    taxonomy
                      .slice(0, 4)
                      .map((term: TaxonomyData, index: number) => (
                        <div key={`taxonomyTerm${index}`}>
                          <dt>{term.key}</dt>
                          <dd>{term.value}</dd>
                        </div>
                      ))}
                </dl>
              </div>
              {slug && (
                <div className="uk-width-1-1 uk-width-1-6@m uk-text-left uk-text-right@s">
                  <Link
                    title={`Discover more about ${title}`}
                    className="uk-button uk-button-link"
                    to={slug}
                  >
                    Discover more
                  </Link>
                </div>
              )}
            </div>
            <div className="c-feature-article__more uk-width-1-1 uk-text-center">
              <div className="uk-margin-medium-top">
                <Link
                  to="/case-studies"
                  className="uk-button uk-button-primary"
                >
                  View all Case Studies
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default FeatureCaseStudy;
